@import 'src/styles/vars';

.Logo {
  display: inline-flex;
}

.Logo__img {
  display: block;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
