@import 'src/styles/vars';

.PlaceholderPage {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.Logo {
  width: 100%;
  max-width: 500px;
  margin-left: -6px;
}

.QrCode {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
}

.TelLink {
  font-size: 24px;
}

.ContactButton {
  height: 64px;
  font-size: 24px;
}
