@font-face {
  font-family: Montserrat;
  src: url('./fonts/montserrat/Montserrat-Regular.eot');
  src: url('./fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/montserrat/Montserrat-Medium.eot');
  src: url('./fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-Medium.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/montserrat/Montserrat-SemiBold.eot');
  src: url('./fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('./fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prosto One';
  src: url('./fonts/prosto_one/ProstoOne-Regular.eot');
  src: url('./fonts/prosto_one/ProstoOne-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/prosto_one/ProstoOne-Regular.woff2') format('woff2'),
    url('./fonts/prosto_one/ProstoOne-Regular.woff') format('woff'),
    url('./fonts/prosto_one/ProstoOne-Regular.ttf') format('truetype'),
    url('./fonts/prosto_one/ProstoOne-Regular.svg#ProstoOne-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
