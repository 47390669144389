@use 'sass:math';

@function invert($v) {
  @return calc(-1 * #{$v});
}

@function bool($value: false) {
  @if $value == false or $value == '' or $value == 'false' or $value == 'false' or $value == 0 {
    @return false;
  }

  @return true;
}

@function design-rel-size($value: 0, $designWidth: $design-size-desktop) {
  @return math.div($value, $designWidth) * 100%;
}

@function percent($value: 1, $totalValue: 1) {
  @return math.div($value, $totalValue) * 100%;
}

@function viewport-percent($value: 0, $viewportMult: $viewport-mult) {
  @return calc(#{$value} * #{$viewportMult});
}

/* девайс с тач-экраном */
@mixin touch-device() {
  @media (pointer: coarse) {
    @content;
  }
}

/* мышь, тачпад */
@mixin mouse-device() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hover {
  @include mouse-device {
    &:hover {
      @content;
    }
  }
}

@mixin focus-visible {
  &:focus-visible {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    opacity: 1;
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-ms-input-placeholder {
    opacity: 1;
    @content;
  }
}

@mixin reset-text-overflow {
  white-space: pre-wrap;
  overflow: visible;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

@mixin text-overflow($linesAmount: 1) {
  @if ($linesAmount == 0) {
    @include reset-text-overflow;
  } @else {
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: $linesAmount;
    line-clamp: $linesAmount;
    -webkit-box-orient: vertical;

    @if ($linesAmount > 1) {
      word-break: break-word;
    } @else {
      word-break: break-all;
    }
  }
}

@mixin scrollbar($showBar: true, $color: #ccc) {
  overflow: scroll;
  scrollbar-color: $color;

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }

  @if (bool($showBar) == true) {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: none;
      border-radius: 4px;
      background-color: $color;
      background-clip: padding-box;

      &:active {
        background-color: $color;
      }
    }
  } @else {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}

@mixin hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin svg-icon {
  svg {
    display: block;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin link-reset {
  text-decoration: none;
  color: inherit;

  &:focus {
    outline: none;
  }

  @include hover {
    text-decoration: none;
  }
}

@mixin button-reset {
  padding: 0;
  margin: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  color: inherit;
  overflow: hidden;
  border: none;
  background: none;
  outline: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  user-select: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
