$bp-mobile-min-width: 280px;
$bp-mobile-max-width: 767px;

$bp-tablet-min-width: 768px;
$bp-tablet-small-max-width: 1024px;
$bp-tablet-max-width: 1280px;

$bp-desktop-min-width: 1281px;
$bp-desktop-small-max-width: 1366px;
$bp-desktop-large-max-width: 1660px;

@mixin mobile() {
  @media (max-width: $bp-mobile-max-width) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $bp-tablet-min-width) and (max-width: $bp-tablet-max-width) {
    @content;
  }
}

@mixin lte-tablet() {
  @media (max-width: $bp-tablet-max-width) {
    @content;
  }
}

@mixin lte-tablet-small() {
  @media (max-width: $bp-tablet-small-max-width) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $bp-tablet-max-width) {
    @content;
  }
}

@mixin lte-desktop-small() {
  @media (max-width: $bp-desktop-small-max-width) {
    @content;
  }
}

@mixin lte-desktop-large() {
  @media (max-width: $bp-desktop-large-max-width) {
    @content;
  }
}

@mixin breakpoint-min($minWidth: 0) {
  @media (min-width: #{$minWidth}px) {
    @content;
  }
}

@mixin breakpoint-max($maxWidth: 0) {
  @media (max-width: #{$maxWidth}px) {
    @content;
  }
}
