@import 'src/styles/vars';

.Button {
  @include button-reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 100%;
  height: 44px;
  padding: 0 24px;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 8px;
  //transition: color $transition-quick, background-color $transition-quick, border-color $transition-quick;
  transition: color $transition-quick, background-color $transition-quick, opacity $transition-quick;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:not(.Button_disabled) {
    &:active {
      opacity: 0.8;
    }

    @include hover {
      opacity: 0.8;
    }
  }

  @include focus-visible {
    outline: 2px solid $color-fill-blue-light;
  }
}

.Button_iconLeft {
  padding-left: 16px;
}

.Button_iconRight {
  padding-right: 16px;
}

.Button_primary {
  background-color: $color-fill-blue-strong;

  &.Button_disabled {
    color: $color-fill-grey-medium;
    background-color: $color-fill-grey-strong;
    opacity: 0.8;
    cursor: not-allowed;
  }

  //&:not(.Button_disabled) {
  //  &:active {
  //    background-color: $color-fill-blue-medium;
  //  }

  //  @include hover {
  //    background-color: $color-fill-blue-medium;
  //  }
  //}
}

.Button_secondary {
  background-color: $color-fill-grey-strong;

  &.Button_disabled {
    background-color: $color-fill-grey-light;
    cursor: not-allowed;
  }

  //&:not(.Button_disabled) {
  //  &:active {
  //    background-color: lighten($color-fill-grey-strong, 5);
  //  }

  //  @include hover {
  //    background-color: lighten($color-fill-grey-strong, 5);
  //  }
  //}
}

.Button_link {
  height: 20px;
  padding: 0;
  color: $color-text-white;
  border: 0;
  background-color: transparent;

  &.Button_disabled {
    color: $color-fill-grey-light;
    cursor: not-allowed;
  }

  //&:not(.Button_disabled) {
  //  &:active,
  //  &.Button_active {
  //    color: $color-fill-grey-medium;
  //  }
  //  @include hover {
  //    color: $color-fill-grey-medium;
  //  }
  //}
}
