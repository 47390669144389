@import 'src/styles/vars';

html {
  color: $color-text-white;
  background-color: $color-fill-black;
  font-family: $font-family-montserrat, $font-family-default;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
