@import 'src/styles/vars';

.Icon {
  @include svg-icon;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.Icon_clickable {
  cursor: pointer;
}

.Icon_medium {
  width: 24px;
  height: 24px;
}

.Icon_small {
  width: 16px;
  height: 16px;
}

.Icon_xSmall {
  width: 12px;
  height: 12px;
}
