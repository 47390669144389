$color-fill-white: #fff;
$color-fill-black: #22262a;
$color-fill-grey-strong: #434f5b;
$color-fill-grey-medium: #9ea4b3;
$color-fill-grey-light: #d7d9df;
$color-fill-blue-strong: #2035f6;
$color-fill-blue-medium: #717ffa;
$color-fill-blue-light: #edeefe;

$color-text-white: #fff;
$color-text-dark-grey: #434f5b;
$color-text-grey-medium: #9ea4b3;
$color-text-blue: #2035f6;

$color-product-card: #333a41;

$color-input-placeholder: #7f879a;
