@import 'src/styles/vars';

.BaseLayout {
  position: relative;
}

.BaseLayout__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100dvh;
  overflow: hidden;
}
