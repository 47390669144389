@import 'src/styles/vars';

.ButtonContactUs {
  gap: 5px;
  padding-left: 11px;
}

.ButtonContactUs__icon {
  width: 40px;
  height: 40px;
}
