@import 'src/styles/vars';

.Header {
  position: fixed;
  z-index: $z-index-header;
  top: -1px;
  left: 0;
  width: 100%;
  height: calc(var(--header-height) + 1px);
  padding: 0 var(--content-padding);
  background: $color-fill-black;
  transition: box-shadow $transition-quick;
}

.Header_scrolled {
  box-shadow: 0 2px 8px 0 darken($color-fill-black, 2);
}

.Header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  max-width: var(--content-max-width);
  height: 100%;
  margin: 0 auto;
}

.Header__ext {
  display: flex;
  align-items: center;
}

.Header__logo {
  width: 254px;
}

.Links {
  display: flex;
  align-items: center;
  gap: 56px;
}

.Link {
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: $color-text-grey-medium;
  font-family: $font-family-prosto-one;
  font-size: 14px;
  line-height: 17px;
  transition: color $transition-quick;

  @include hover {
    color: $color-text-white;
  }
}

.Link_active {
  color: $color-text-white;
}

.TelegramButton {
  margin-left: 24px;
}

// MOBILE

.BurgerButton {
  width: var(--header-height);
  height: var(--header-height);
}

.MobileNavOffset {
  position: fixed;
  z-index: $z-index-mobile-nav;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-fill-grey-medium;
  opacity: 0;
  transition: opacity $transition-slow, top 0s;
  transition-delay: 0s, $transition-slow;
}

.MobileNavOffset_open {
  top: 0;
  opacity: 0.5;
  transition-delay: 0s;
}

.MobileNav {
  position: fixed;
  z-index: $z-index-mobile-nav;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-max-width);
  max-height: 100%;
  border-radius: 16px 16px 0 0;
  background: #333a41;
  transition: transform $transition-slow ease;
  transform: translate3d(-50%, 100%, 0);
}

.MobileNav_open {
  transform: translate3d(-50%, 0, 0);
}

.MobileNav__closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 52px;
  height: 52px;
}

.MobileNav__content {
  flex-grow: 1;
  padding: 40px;
  overflow-y: auto;
}

.MobileNav__links {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.MobileNav__link {
  @include link-reset;
  align-self: flex-start;
  font-family: $font-family-montserrat;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
}

.MobileNav__divider {
  height: 1px;
  background: $color-fill-grey-strong;
}

@include lte-tablet {
  .Header {
    position: sticky;
    margin-top: calc(var(--content-padding) - 1px);
  }

  .Header__logo {
    width: auto;
    height: 100%;
    padding: 12px 0;
  }
}
