@import 'src/styles/vars';

.ButtonScrollUp {
  @include button-reset;
  position: relative !important;
  display: block;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-left: auto;
  border-radius: 8px;
  background: $color-fill-grey-strong;
  transition: background $transition-quick;

  @include hover {
    background: $color-fill-black;
  }

  @include focus-visible {
    outline: 2px solid $color-fill-blue-light;
  }
}

.ButtonScrollUp__icon {
  width: 100%;
  height: 100%;
}
