@import 'src/styles/vars';

.Footer {
  padding: 40px var(--content-padding);
  color: $color-fill-grey-medium;
  background: #333a41;
  font-family: $font-family-montserrat;
  font-size: 16px;
  line-height: 28px;
}

.Footer__content {
  display: flex;
  justify-content: space-between;
  gap: 120px;
  max-width: var(--content-max-width);
  margin: 0 auto;
}

.Footer__logo {
  width: 256px;
}

.Footer__nav {
  flex-grow: 1;
}

.Footer__right {
  display: flex;
  align-items: flex-end;
  gap: 40px;
}

.Telegram {
  margin-top: 8px;
}

.Links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Link {
  transition: color $transition-quick;

  @include hover {
    color: $color-text-white;
  }
}

.Copyright {
  margin-top: 24px;
}

.QrCode {
  display: block;
  width: 124px;
}

@include lte-tablet {
  .Footer__content {
    flex-direction: column;
    gap: 32px;
  }

  .Copyright {
    margin-top: 0;
  }
}
