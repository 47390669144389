@import 'vars';

:root {
  --header-height: 88px;
  --content-padding: #{$content-side-padding}px;
  --content-max-width: 1920px;

  --transition-quick-ms: #{$transition-quick-ms};
  --transition-slow-ms: #{$transition-slow-ms};

  --bp-mobile-min-width: #{$bp-mobile-min-width};
  --bp-mobile-max-width: #{$bp-mobile-max-width};
  --bp-tablet-min-width: #{$bp-tablet-min-width};
  --bp-tablet-small-max-width: #{$bp-tablet-small-max-width};
  --bp-tablet-max-width: #{$bp-tablet-max-width};
  --bp-desktop-min-width: #{$bp-desktop-min-width};
  --bp-desktop-small-max-width: #{$bp-desktop-small-max-width};
  --bp-desktop-large-max-width: #{$bp-desktop-large-max-width};
}

@include lte-tablet {
  :root {
    --header-height: 52px;
    --content-padding: #{$content-side-padding-mobile}px;
    --content-max-width: 450px;
  }
}
